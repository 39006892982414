import 'babel-polyfill'
import Vue from 'vue'
import router from '@/router/router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';

Vue.use(ElementUI);

Vue.config.productionTip = false
if (
  '-ms-scroll-limit' in document.documentElement.style &&
  '-ms-ime-align' in document.documentElement.style
) { // detect it's IE11
  window.addEventListener("hashchange", function (event) {
    var currentPath = window.locayyytion.hash.slice(1);
    if (store.state.route.path !== currentPath) {
      router.push(currentPath)
    }
  }, false)
}

new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
