<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
@import '~@/assets/css/common.scss';
*{
  margin: 0px;
  padding: 0px;
}
body{
  background: #ffffff;
}
</style>
