import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

//添加以下代码
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)

}
const routes = [
  //首页
  {
    path: '/', name: 'indexPage', component: () => import('@/page/Home/index'),
  },
  //服务介绍
  {
    path: '/ServiceIntroduction', name: 'ServiceIntroduction', component: () => import('@/page/ServiceIntroduction/index'),
  },
  //艺术画屏
  {
    path: '/ArtScreen', name: 'ArtScreen', component: () => import('@/page/ArtScreen/index'),
  },
  //下载App
  {
    path: '/DownloadApp', name: 'DownloadApp', component: () => import('@/page/DownloadApp/index'),
  },
  //服务费用
  {
    path: '/service', name: 'service', component: () => import('@/page/service/index'),
  },
  //招商中心
  {
    path: '/InvestmentPromotionCenter', name: 'InvestmentPromotionCenter', component: () => import('@/page/InvestmentPromotionCenter/index'),
  },
  //伴手礼应用场景
  {
    path: '/Souvenir', name: 'Souvenir', component: () => import('@/page/ApplicationScenario/Souvenir'),
  },
  //美术展览馆应用场景
  {
    path: '/museum', name: 'museum', component: () => import('@/page/ApplicationScenario/museum'),
  },
  //影楼摄影应用场景
  {
    path: '/CinemaPhotography', name: 'CinemaPhotography', component: () => import('@/page/ApplicationScenario/CinemaPhotography'),
  },
  //家装酒店应用场景
  {
    path: '/HomeDecoration', name: 'HomeDecoration', component: () => import('@/page/ApplicationScenario/HomeDecoration'),
  },
  //党建宣传应用场景
  {
    path: '/PartybuildingPublicity', name: 'PartybuildingPublicity', component: () => import('@/page/ApplicationScenario/PartybuildingPublicity'),
  },
  //培训机构应用场景
  {
    path: '/TrainingInstitutions', name: 'TrainingInstitutions', component: () => import('@/page/ApplicationScenario/TrainingInstitutions'),
  },
  //旅游宣传应用场景
  {
    path: '/Tourismpromotion', name: 'Tourismpromotion', component: () => import('@/page/ApplicationScenario/Tourismpromotion'),
  },
  //展览/展演
  {
    path: '/Performance', name: 'Performance', component: () => import('@/page/Performance/index'),
  },
  //签约艺术家
  {
    path: '/Scenarios', name: 'Scenarios', component: () => import('@/page/ApplicationScenario/Scenarios'),
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  window.scrollTo(0, 0);
  next()
})
export default router
